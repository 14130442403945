.some {
  width: 60vw;
  height: 90%;
  backgroundColor: white;
}

#interactive.viewport {
  position: absolute;
  border: 1px red solid;
  backgroundColor: red;
  width: 40px;
  height: auto;
  overflow: hidden;
  text-align: center;
  z-index: 99
}

#interactive.viewport > canvas,
#interactive.viewport > video {
  backgroundColor: blue;
  max-width: 40px;
  width: 40px;
  max-height: fit-content;
}

canvas.drawing,
canvas.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
