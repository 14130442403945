input.checked::before {
    content: "";
    -webkit-mask-image: url("../../assets/svg/Tick.svg");
    mask-image: url("../../assets/svg/Tick.svg");
    mask-size: 60%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    mask-repeat: no-repeat;
    position: absolute;
    top: 0;
    mask-position: center;
}

input.minus::before {
    content: "";
    -webkit-mask-image: url("../../assets/svg/Minus.svg");
    mask-image: url("../../assets/svg/Minus.svg");
    mask-size: 60%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    mask-repeat: no-repeat;
    position: absolute;
    top: 0;
    mask-position: center;
}