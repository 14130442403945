#html5-qrcode-button-camera-permission, #html5-qrcode-button-camera-start, #html5-qrcode-button-camera-stop {
    padding: 2px 12px;
    margin-bottom: 10px;
    color: white;
    border-radius: 5px;
}

#html5-qrcode-button-camera-permission, #html5-qrcode-button-camera-start {
    background-color: #132044;
}

#html5-qrcode-button-camera-stop {
    background-color: #932223;
}

#html5-qrcode-button-camera-stop:hover {
    background-color: #772021;
}

#html5-qrcode-button-camera-permission:hover, #html5-qrcode-button-camera-start:hover {
    background-color: #1C3D96;
}

#qrRegion > div:first-child {
    display: none;
}

#qrRegion {
    border-radius: 15px;
}

#qrRegion__scan_region {
    display: flex;
    justify-content: center;
}

#html5-qrcode-anchor-scan-type-change {
    text-decoration: none !important;
    font-size: 11px;
    cursor: pointer;
    padding: 2px 8px;
    border-radius: 5px;
    margin-top: 20px;
    /* box-shadow: inset 0 0 0 1px #132044; */
}

#html5-qrcode-anchor-scan-type-change:hover {
    background-color: #ECEFF9;
}
