#toggle-button {
  display: flex;
  width: fit-content;
  border-radius: 50%;
  z-index: 999;
}

.switch {
  position: relative;
  width: 32px;
  height: 17px;
  border-radius: 50%;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #772021;
  background-image: var(--toggle-bg);
  background-size: cover;
  background-repeat: no-repeat;
  transition: 0.7s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: -1px;
  bottom: 3px;
  background-size: cover;
  background-repeat: no-repeat;
  transition: 0.7s;
}

input:checked + .slider {
  background-color: #132044;
}

input:checked + .slider:before {
  transform: translateX(16px);
  background-color: rgb(255, 255, 255);
  background-size: cover;
  background-repeat: no-repeat;
}

.slider.round {
  border-radius: 26px;
}

.slider.round:before {
  margin: auto;
  /* top: 1.5px; */
  left: 2.1px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
}
